<template>
  <v-card elevation="3" min-height="100vh" class="rounded-10 campaign-emails">
    <VueTable
      :items="items"
      :headers="headers"
      :show-row-actions="true"
      @load-more="loadMoreEmails"
      :show-select="false"
      :has-header="false"
      icon="mdi-alpha-e-box-outline"
      title="Campaign Emails"
      :key="componentKey"
      :no-more-data="!has_more_items"
      empty-text="No emails yet"
      :loading="loading_items"
    >
      <template v-slot:header-left-toolbar>
        <h1 class="fs-20 my-2 fw-900 primary--text">
          {{ `Email Logs : ${(campaign && campaign.name) || ''}` }}
        </h1>
      </template>
      <template v-slot:header-toolbar>
        <v-row dense>
          <v-spacer />
          <v-btn
            color="primary"
            depressed
            class="text-none"
            @click="
              $router.push({ name: 'campaigns', params: { type: 'email' } })
            "
          >
            <v-icon left>mdi-arrow-left-bold-circle-outline</v-icon>
            Back
          </v-btn>
          <v-menu bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                depressed
                class="ml-1 text-none"
                v-bind="attrs"
                v-on="on"
              >
                Status: {{ filters.status | ucwords }}
                <v-icon right>mdi-menu-swap</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                v-for="item in ['all', 'sent', 'not_sent']"
                :key="item"
                @click="filters.status = item"
              >
                <v-list-item-title class="subtitle-2 primary--text">
                  {{ item | ucwords }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-row>
      </template>
      <template v-slot:row-slot="{ item }">
        <td :id="`emails-${item.id}`">{{ item.id }}</td>
        <td>{{ item.destination_email }}</td>
        <td>{{ item.subject }}</td>
        <td class="text-center">{{ item.status | ucwords }}</td>
        <td class="text-center">{{ item.created_at | format('lll') }}</td>
        <ActionDropdown
          :item="item"
          :has-edit="false"
          :has-delete="true"
          :has-view="true"
          @view="action_view_dialog(true, item)"
          @delete="action_confirm_dialog(true, item)"
        >
        </ActionDropdown>
      </template>
    </VueTable>
    <DeleteDialog
      :open-dialog.sync="confirm_dialog"
      title="Delete Email"
      text-content="Are you sure you want to delete this email?"
      @delete="handleDeleteEmail"
      delete-button-text="Yes! Delete Email"
    ></DeleteDialog>
    <EmailView :dialog.sync="view_dialog" :item="activeItem"></EmailView>
  </v-card>
</template>

<script>
import VueTable from '@/common/VueTable/VueTable.vue'
import ActionDropdown from '@/common/VueTable/ActionDropdown.vue'
import DeleteDialog from '@/common/DeleteDialog.vue'
import { fetch_services } from '@/services/fetch/fetch_services'
import http_build_query from 'js-http-build-query'
import EmailView from '@/modules/Campaign/components/EmailView'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CampaignEmails',
  components: { EmailView, DeleteDialog, ActionDropdown, VueTable },
  mixins: [fetch_services],
  data: () => ({
    componentKey: 0,
    headers: [
      { text: 'ID', value: 'id', sortable: true, align: 'left', width: 70 },
      {
        text: 'To',
        value: 'destination_email',
        sortable: true,
        align: 'left',
        width: 220
      },
      { text: 'Subject', value: 'subject', sortable: true, align: 'left' },
      {
        text: 'Status',
        value: 'status',
        sortable: true,
        align: 'center',
        width: 90
      },
      {
        text: 'Created',
        value: 'created_at',
        sortable: true,
        align: 'center',
        width: 200
      },
      {
        text: 'Action',
        value: 'action',
        sortable: false,
        align: 'center',
        width: 40
      }
    ],
    filters: {
      status: 'all'
    },
    campaign_id: null
  }),
  watch: {
    '$route.params': {
      handler: function (val) {
        this.campaign_id = val.id
      },
      immediate: true
    },
    filters: {
      handler: function (val) {
        this.loadEmails()
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapGetters('campaigns', ['campaign'])
  },
  created() {
    if (this.campaign_id) {
      this.loadEmails()
      this.fetchCampaign({ id: this.campaign_id })
    }
  },
  methods: {
    ...mapActions('campaigns', ['fetchCampaign']),
    loadEmails() {
      this.loadItems(
        `api/lead-campaign/email/emails/${this.campaign_id}?${http_build_query(
          this.filters
        )}`,
        true
      )
    },
    loadMoreEmails() {
      this.loadMoreItems()
    },
    handleDeleteEmail() {
      this.deleteItem(
        `api/lead-campaign/email/emails/${this.campaign_id}/${this.activeItem.id}`,
        'Email deleted',
        this.action_confirm_dialog(false, null),
        null
      )
    }
  }
}
</script>

<style scoped lang="scss"></style>
