<template>
  <CustomDialog
    :open.sync="open"
    title="Email Viewer"
    @click-close="close_dialog"
    @button1="close_dialog"
    :max-width="mdAndDown ? `100%` : 1024"
    ref="dialog"
    :has-button2="false"
    button1-text="Close"
  >
    <template v-slot:content>
      <v-card flat>
        <v-card-text class="py-0">
          <v-simple-table dense class="border-color--grey border-1 mb-1">
            <template v-slot:default>
              <tbody>
                <tr>
                  <td class="primary--text fw-700">Subject</td>
                  <td class="primary--text fw-500">{{ item.subject }}</td>
                </tr>
                <tr>
                  <td class="primary--text fw-700">Destination Email</td>
                  <td class="primary--text fw-500">
                    {{ item.destination_email }}
                  </td>
                </tr>
                <tr>
                  <td class="primary--text fw-700">From Email</td>
                  <td class="primary--text fw-500">
                    {{ item.props.from_email }}
                  </td>
                </tr>
                <tr>
                  <td class="primary--text fw-700">CC</td>
                  <td class="primary--text fw-500">{{ item.props.cc }}</td>
                </tr>
                <tr>
                  <td class="primary--text fw-700">Reply To</td>
                  <td class="primary--text fw-500">
                    {{ item.props.reply_to }}
                  </td>
                </tr>
                <tr>
                  <td class="primary--text fw-700">Created At</td>
                  <td class="primary--text fw-500">
                    {{ item.created_at | format('LLL') }}
                  </td>
                </tr>
                <tr>
                  <td class="primary--text fw-700">Status</td>
                  <td class="primary--text fw-500">
                    {{ item.status | ucwords }}
                  </td>
                </tr>
                <tr>
                  <td class="primary--text fw-700">Email Period</td>
                  <td class="primary--text fw-500">
                    {{ item.sent_from | format('LLL') }} -
                    {{ item.sent_to | format('LLL') }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div
            oncontextmenu="return false;"
            style="min-height: 100%"
            class="fullwidth"
            v-show="item && item.body"
          >
            <iframe
              id="iframe"
              class="iframe border-1 border-color--grey rounded-10 pa-2"
              @load="onIframeLoad(this)"
              :src="previews"
            ></iframe>
          </div>
        </v-card-text>
      </v-card>
    </template>
  </CustomDialog>
</template>

<script>
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'

export default {
  name: 'EmailView',
  components: { CustomDialog },
  props: {
    item: Object,
    dialog: { type: Boolean, default: false }
  },
  data: () => ({
    open: false
  }),
  computed: {
    previews() {
      if (this.item && this.item.body.trim() !== '')
        return this.getBlobURL(this.item.body, 'text/html')
      return ''
    }
  },
  watch: {
    dialog(val) {
      this.open = val
    },
    open(val) {
      this.$emit('update:dialog', val)
    }
  },
  methods: {
    getBlobURL(code, type) {
      const blob = new Blob([code], { type })
      return URL.createObjectURL(blob)
    },
    onIframeLoad(iframe) {
      if (iframe) {
        iframe.height = iframe.contentWindow.document.body.scrollHeight + 'px'
      }
    },
    close_dialog() {
      this.open = false
    }
  }
}
</script>

<style scoped lang="scss">
.iframe {
  width: 100% !important;
  min-height: 98vh;
  border: none;
}
</style>
